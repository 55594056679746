import React, { useEffect } from "react"
import PropTypes from "prop-types"
import TagManager from "react-gtm-module"
import { AP_STAGE } from "@/helpers"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import dynamic from "next/dynamic"
import theme from "@/theme"
import store from "@/store"
import { Provider } from "react-redux"

const tagManagerArgs = {
  gtmId: "GTM-K29J963",
}

function MyApp(props) {
  const { Component, pageProps } = props

  useEffect(() => {
    if (AP_STAGE === "production") {
      TagManager.initialize(tagManagerArgs)
    }
  }, [])

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <Component {...pageProps} />
        </Provider>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default dynamic(() => Promise.resolve(MyApp), {
  ssr: false,
})

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
}
